exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cv-index-js": () => import("./../../../src/pages/cv/index.js" /* webpackChunkName: "component---src-pages-cv-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notebooks-index-js": () => import("./../../../src/pages/notebooks/index.js" /* webpackChunkName: "component---src-pages-notebooks-index-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog-details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-cv-details-js": () => import("./../../../src/templates/cv-details.js" /* webpackChunkName: "component---src-templates-cv-details-js" */),
  "component---src-templates-notebooks-details-js": () => import("./../../../src/templates/notebooks-details.js" /* webpackChunkName: "component---src-templates-notebooks-details-js" */)
}

